<template>
  <div class="questionnaireEdit">
    <div class="top-btn">
      <v-button text="返回" @click="goBack"></v-button>
    </div>
    <div class="bottom-wrap">
      <v-tabs :tabs="tabs" v-model="activeName"></v-tabs>
      <create-activity
        v-show="activeName === 'create'"
        :id="id"
        :isView="isView"
        @updateData="baseSuccessCB"
        sortLabel="问卷排序"
      ></create-activity>
      <small-test
        :id="id"
        :isView="isView"
        :viewStatus="viewStatus"
        :saveKey="saveKey"
        :answerCount="answerCount"
        v-show="activeName === 'smallTest'"
        @view="viewCB"
      ></small-test>
    </div>
    <question-view
      :isPreviewShow.sync="isPreviewShow"
      :detail="detail"
      :activityName="activityName"
    ></question-view>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import createActivity from "./components/createActivity.vue";
import smallTest from "./components/smallTest.vue";
import questionView from "./components/questionView.vue";

export default {
  name: "addActivity",
  components: {
    createActivity,
    smallTest,
    questionView,
  },
  data() {
    return {
      id: undefined,
      tabs: [
        {
          label: "活动创建",
          disabled: false,
          name: "create",
        },
        {
          label: "课后小测试",
          disabled: false,
          name: "smallTest",
        },
      ],
      detail: {
        questionList: [],
      },
      saveKey: 0,
      isPreviewShow: false,
      answerCount: 0,
      isView: false,
      activeName: "create",
      viewStatus: false,
      activityName: "",
    };
  },
  created() {
    const { id, isView } = this.$route.query;
    if (id !== undefined) {
      this.id = id;
    }
    if (isView !== undefined) {
      this.isView = JSON.parse(isView);
    }
    this.tabs[1].disabled = this.id === undefined;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 点击预览按钮
    viewCB(list) {
      this.isPreviewShow = true;
      this.detail.questionList = list;
    },
    // 基础信息成功保存后的回调
    baseSuccessCB(d, onOff) {
      const data = _.cloneDeep(d);
      this.detail = { ...data, questionList: this.detail.questionList };
      if (onOff) {
        this.activeName = "smallTest";
      }
      this.id = data.scheduleList[0].id;
      // this.isView = moment(data.endDate).isBefore(new Date());

      if (
        data.activityStatus == 1 ||
        data.activityStatus == 2 ||
        data.activityStatus == 3
      ) {
        this.isView = false;
        this.viewStatus = true;
      } else {
        this.isView = true;
        this.viewStatus = false;
      }
      console.log(this.viewStatus, "viewStatus");
      // this.answerCount = data.activityStatus;
      this.activityName = data.activityName;
      this.tabs[1].disabled = false;
      this.saveKey += 1;
      // console.log(this.answerCount, "this.answerCount");
    },
  },
};
</script>
<style lang="less" scoped>
.questionnaireEdit {
  box-sizing: border-box;
  height: 100%;
  .top-btn {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
  }
  .bottom-wrap {
    height: calc(100% - 50px);
  }
}
</style>
